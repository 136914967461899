
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/bootstrap'; 

@import "~toastr/toastr.scss";

div.page-header {
    margin: unset !important;
    border: 0 !important;
    background: var(--page-header-bg-color);
    color: #fff;
}
  
nav.page-header {
    background-color: var(--nav-bg-color);
}

div.page-footer {
    background: var(--page-header-bg-color);
    color: #fff;
}

div.left-nav {
    background: var(--nav-left-bg-color) !important;
}

.nav-item > .nav-link:hover {
    background: var(--nav-left-hover-bg-color) !important;
}
  
ul.nav li.nav-item.dropdown.show .nav-link {
    background: var(--nav-left-hover-bg-color) !important;
}

ul.nav li.nav-item div.dropdown-menu .dropdown-item:hover {
    background: var(--nav-left-hover-bg-color) !important;
    color: #fff !important;
}

ul.auth-menu div.dropdown-menu {
    background-color: var(--nav-left-bg-color);
    color: #fff !important;
}

.nav-item.dropdown .nav-link.dropdown-toggle {
    color: #fff !important;
}

ul.nav .nav-item .dropdown-menu.show {
    background: var(--nav-left-bg-color) !important;
}

ul.auth-menu > li.nav-item.dropdown .dropdown-item, ul.auth-menu > li.nav-item.dropdown .dropdown-item > .btn-link {
    color: #fff !important;
    text-decoration: none;
}

ul.auth-menu > li.nav-item.dropdown .dropdown-item:hover {
    background: var(--nav-left-hover-bg-color) !important;
}
